import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Step,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import Image from 'next/image';
import { useEffect, useRef } from 'react';

import { firstElementIndex, firstRank, tableRowLimit } from '../pageHelpers';
import PlayerProfileLink from '../PlayerProfileLink';
import LeaderboardHeader from './LeaderboardHeader';
import RecruitmentCenterSkeleton from './RecruitmentCenterSkelton';
import Link from 'components/MUI/Link';
import ContentContainer from 'components/shared/ContentContainer';
import PageContainer from 'components/shared/PageContainer';
import CustomStepAvatar from 'components/shared/Stepper/CustomStepAvatar';
import CustomStepContent from 'components/shared/Stepper/CustomStepContent';
import CustomStepper from 'components/shared/Stepper/CustomStepper';
import { getPlayerPositionByRegion } from 'helpers/getPositionsByRegion';
import { InferTRPCOutputTypes, trpc } from 'helpers/trpc';

type RecruitmentCenterLeaderboardPlayer =
  InferTRPCOutputTypes['home']['getRecruitmentCenterLeaderboard']['leaderboardPlayers'][number];

const customStyles = {
  otherCell: {
    color: '#1A1A1A',
  },
  nameSkelton: {
    width: '10vw',
    height: 15,
    maxWidth: 'calc(100% - 10px)',
  },
  teamSkelton: {
    width: '6vw',
    height: 15,
    maxWidth: 'calc(100% - 10px)',
  },
  avatarCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  leaderboardGrid: {
    minHeight: 500,
  },
  contentContainer: {
    height: '100%',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
  name: {
    maxWidth: 'calc(100% - 10px)',
    mb: 0,
    mt: -1.2,
    cursor: 'pointer',
  },
  watchListStack: {
    maxHeight: 440,
    px: 4,
    overflow: 'hidden',
    ':hover': {
      overflowY: 'auto',
    },
  },
  watchListPlayerStack: {
    py: 2,
    alignItems: 'center',
  },
  detailBox: {
    px: 4,
  },
  playerName: {
    color: 'black',
  },
  emptyBox: {
    ml: -2,
    mt: -2,
  },
  pageContainer: {
    mt: 0.5,
  },
  icon: {
    color: 'black !important',
  },
  team: {
    mt: -2,
    color: 'typography.secondary',
  },
  stepperText: {
    mb: -1,
    color: 'typography.secondary',
  },
  mainBox: {
    maxHeight: 430,
    overflow: 'hidden',
    ':hover': {
      overflowY: 'auto',
    },
  },
  player: {
    pl: 0,
  },
  avatarWrapper: {
    cursor: 'pointer',
  },
};

const RecruitmentSection = () => {
  const { data: currentUser } = trpc.shared.currentUser.useQuery();
  const initialLeaderboardFetching = useRef(true);
  const initialWatchlistFetching = useRef(true);

  const {
    data: recruitmentCenterLeaderboardData,
    isFetching: isLeaderboardFetching,
    refetch: refetchLeaderboard,
  } = trpc.home.getRecruitmentCenterLeaderboard.useQuery(undefined, {
    enabled: initialLeaderboardFetching.current,
  });

  const {
    data: recruitmentCenterWatchlistData,
    isFetching: isWatchlistFetching,
    refetch: refetchWatchlist,
  } = trpc.home.getRecruitmentCenterWatchlist.useQuery(undefined, {
    enabled: initialWatchlistFetching.current,
  });

  const isDataFetching = isLeaderboardFetching || isWatchlistFetching;

  const getRankedItems = (list: RecruitmentCenterLeaderboardPlayer[]) => {
    const sortedList = [...list].sort(
      (previous, next) => next.nrsScore - previous.nrsScore
    );

    let rank = firstRank;
    let prevScore = sortedList[firstElementIndex]?.nrsScore;

    return sortedList.map((item, index) => {
      if (item.nrsScore < prevScore) {
        rank = index + firstRank;
        prevScore = item.nrsScore;
      }

      return { ...item, rank };
    });
  };

  useEffect(() => {
    refetchLeaderboard();
  }, [refetchLeaderboard, currentUser?.club.activeClubId]);

  useEffect(() => {
    refetchWatchlist();
  }, [refetchWatchlist, currentUser?.club.activeClubId]);

  useEffect(() => {
    if (recruitmentCenterLeaderboardData) {
      initialLeaderboardFetching.current = false;
    }
  }, [recruitmentCenterLeaderboardData]);

  useEffect(() => {
    if (recruitmentCenterWatchlistData) {
      initialWatchlistFetching.current = false;
    }
  }, [recruitmentCenterWatchlistData]);

  const leaderboardSection = (
    <Grid item xs={12} md={8} sx={customStyles.leaderboardGrid}>
      <ContentContainer
        containerTitle="Leaderboard"
        containerRight={
          <Link href="/leaderboards">
            <IconButton>
              <KeyboardArrowRightIcon sx={customStyles.icon} />
            </IconButton>
          </Link>
        }
        sx={customStyles.contentContainer}
      >
        <TableContainer>
          <Table>
            <LeaderboardHeader />

            <TableBody>
              {recruitmentCenterLeaderboardData?.leaderboardPlayers.length! >
              0 ? (
                getRankedItems(
                  recruitmentCenterLeaderboardData?.leaderboardPlayers.slice(
                    0,
                    tableRowLimit
                  ) ?? []
                ).map((player, index) => (
                  <TableRow key={index}>
                    <TableCell sx={customStyles.otherCell} align="center">
                      {player.rank}
                    </TableCell>

                    <TableCell align="center">
                      <Box sx={customStyles.avatarCell}>
                        <PlayerProfileLink playerId={player.id}>
                          <Avatar src={player.profileImagePath} />
                        </PlayerProfileLink>
                      </Box>
                    </TableCell>

                    <TableCell align="left" sx={customStyles.player}>
                      <Box sx={customStyles.name}>
                        <PlayerProfileLink playerId={player.id}>
                          <Typography variant="h3">{player.name}</Typography>
                        </PlayerProfileLink>
                      </Box>

                      <Box>
                        <Typography sx={customStyles.team}>
                          {player.team}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell align="center" sx={customStyles.otherCell}>
                      {player.countryImage && (
                        <Image
                          src={player.countryImage}
                          width={40}
                          height={25}
                          alt="country"
                        />
                      )}
                    </TableCell>

                    <TableCell align="center" sx={customStyles.otherCell}>
                      {player.age}
                    </TableCell>

                    <TableCell align="center" sx={customStyles.otherCell}>
                      {player.nrsScore}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    No players available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentContainer>
    </Grid>
  );

  const watchListSection = (
    <Grid item xs={12} md={4}>
      <ContentContainer
        containerTitle="Watchlist"
        containerRight={
          <Link href="/playermanagement">
            <IconButton>
              <KeyboardArrowRightIcon sx={customStyles.icon} />
            </IconButton>
          </Link>
        }
        sx={customStyles.contentContainer}
      >
        <Box sx={customStyles.mainBox}>
          <CustomStepper>
            {recruitmentCenterWatchlistData?.watchlistPlayers?.length! > 0 ? (
              recruitmentCenterWatchlistData?.watchlistPlayers?.map(
                (player, index) => (
                  <Step key={index} expanded={true}>
                    <PlayerProfileLink playerId={player.id}>
                      <CustomStepAvatar imageSource={player.profileImage} />
                    </PlayerProfileLink>

                    <CustomStepContent
                      currentStep={index}
                      steps={
                        recruitmentCenterWatchlistData?.watchlistPlayers?.length
                      }
                    >
                      <Box minHeight={40}>
                        <PlayerProfileLink playerId={player.id}>
                          <Typography variant="h3" mb={0}>
                            {player.name}
                          </Typography>
                        </PlayerProfileLink>

                        <Typography sx={customStyles.stepperText}>
                          {getPlayerPositionByRegion(player.position)}
                        </Typography>
                      </Box>
                    </CustomStepContent>
                  </Step>
                )
              )
            ) : (
              <Typography sx={customStyles.emptyBox}>
                No watchlist players available
              </Typography>
            )}
          </CustomStepper>
        </Box>
      </ContentContainer>
    </Grid>
  );

  const recruitmentCenter = (
    <Grid container spacing={6}>
      {leaderboardSection}

      {watchListSection}
    </Grid>
  );

  return (
    <PageContainer
      pageTitle="Recruitment Centre"
      sx={customStyles.pageContainer}
    >
      {isDataFetching ? (
        <RecruitmentCenterSkeleton accessAvailable />
      ) : (
        recruitmentCenter
      )}
    </PageContainer>
  );
};

export default RecruitmentSection;
