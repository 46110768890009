import { Avatar, Box, TableCell, TableRow, Typography } from '@mui/material';

import { defaultStringValue } from '../pageHelpers';
import PlayerProfileLink from '../PlayerProfileLink';
import { RankedMetricLeaderboardPlayer } from 'components/shared/AcademyAnalysis/types';
import { getPlayerPositionByRegion } from 'helpers/getPositionsByRegion';
import { LabLeaderboardPlayer } from 'server/routers/academyAnalysis/types';

const customStyles = {
  otherCell: {
    fontWeight: 500,
  },
  avatarCell: {
    display: 'flex',
    justifyContent: 'center',
  },
  playerName: {
    maxWidth: 'calc(100% - 10px)',
    ml: -1,
  },
  team: {
    mt: -1,
    color: 'typography.secondary',
  },
  positionCell: {
    pl: 2,
    fontWeight: 500,
  },
};

interface Props {
  player: RankedMetricLeaderboardPlayer;
}

const LeaderboardTableRow = ({ player }: Props) => (
  <TableRow>
    <TableCell align="center" sx={customStyles.otherCell}>
      {player.rank}
    </TableCell>

    <TableCell align="center" sx={customStyles.otherCell}>
      <Box sx={customStyles.avatarCell}>
        <PlayerProfileLink playerId={player.id}>
          <Avatar src={player.profileImagePath} />
        </PlayerProfileLink>
      </Box>
    </TableCell>

    <TableCell align="left">
      <Box sx={customStyles.playerName}>
        <PlayerProfileLink playerId={player.id}>
          <Typography variant="h3" mb={1}>
            {player.name}
          </Typography>
        </PlayerProfileLink>

        <Box>
          <Typography sx={customStyles.team}>{player.team}</Typography>
        </Box>
      </Box>
    </TableCell>

    <TableCell align="left" sx={customStyles.positionCell}>
      {getPlayerPositionByRegion(player.position)}
    </TableCell>

    <TableCell align="center" sx={customStyles.otherCell}>
      {`${player.score} ${
        (player as LabLeaderboardPlayer).unit ?? defaultStringValue
      }`}
    </TableCell>
  </TableRow>
);

export default LeaderboardTableRow;
