import { Box, SxProps, Typography, useTheme } from '@mui/material';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { mainThemeBoldFontFamily } from 'components/MUI/typography';

interface BarChartData {
  xAxis: string;
  value: number;
}

interface Props {
  barHeight: number;
  emptyMessage: string;
  data: BarChartData[];
  tooltipLabel?: string;
  legendLabel?: string;
  wrapperStyles?: SxProps;
  showLegend?: boolean;
}

const customStyles = {
  axis: {
    fill: 'black',
    fontFamily: mainThemeBoldFontFamily,
  },
  tooltipBox: {
    backgroundColor: 'black',
    borderRadius: 8,
    py: 1,
    px: 6,
    color: 'white',
  },
  innerBox: {
    display: 'flex',
    alignItems: 'center',
  },
  tooltipColorBox: {
    display: 'inline-block',
    width: '10px',
    height: '10px',
    mr: 1.25,
    borderRadius: 32,
    backgroundColor: 'primary.main', // equal to theme.palette.primary.main
  },
  emptyText: {
    justifyContent: 'center',
    mt: 8,
  },
  legend: {
    color: 'black',
    fontFamily: mainThemeBoldFontFamily,
  },
};

const CustomBarChart = ({
  data,
  barHeight,
  emptyMessage,
  tooltipLabel,
  legendLabel,
  wrapperStyles,
  showLegend,
}: Props) => {
  const theme = useTheme();

  const empty = data?.length === 0;

  const xAxis = (
    <>
      {!empty && (
        <XAxis
          dataKey="xAxis"
          style={customStyles.axis}
          axisLine={false}
          tickLine={{ stroke: 'none' }}
        />
      )}
    </>
  );

  const yAxis = (
    <YAxis
      ticks={
        empty
          ? Array.from(Array(11).keys()).map((index) => index / 10)
          : undefined
      }
      tickLine={{ stroke: 'none' }}
      type="number"
      axisLine={false}
      style={customStyles.axis}
    />
  );

  const toolTip = (
    <>
      {!empty && (
        <Tooltip
          wrapperStyle={{ outline: 'none' }}
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box sx={customStyles.tooltipBox}>
                  <Box>{label}</Box>

                  {payload.map((entry, index) => (
                    <Box key={`tooltip-${index}`} sx={customStyles.innerBox}>
                      <Box component="span" sx={customStyles.tooltipColorBox} />
                      <Box component="span">{`${entry.value} ${tooltipLabel}`}</Box>
                    </Box>
                  ))}
                </Box>
              );
            }

            return null;
          }}
        />
      )}
    </>
  );

  const bar = (
    <Bar dataKey="value" radius={8}>
      {data?.map((entry) => (
        <Cell key={entry.xAxis} fill={theme.palette.primary.main} />
      ))}
    </Bar>
  );

  const legend = (
    <Legend
      iconSize={10}
      payload={[{ value: legendLabel, color: theme.palette.primary.main }]}
      formatter={(value) => <span style={customStyles.legend}>{value}</span>}
    />
  );

  return (
    <Box sx={wrapperStyles}>
      <ResponsiveContainer width="100%" height={barHeight}>
        <BarChart data={data}>
          <CartesianGrid vertical={false} strokeDasharray="3 3" />

          {xAxis}

          {yAxis}

          {toolTip}

          {bar}

          {showLegend && legend}
        </BarChart>
      </ResponsiveContainer>

      {empty && (
        <Typography variant="h3" sx={customStyles.emptyText}>
          {emptyMessage}
        </Typography>
      )}
    </Box>
  );
};

export default CustomBarChart;
